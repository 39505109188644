import axios from "axios";
import { API_DOMAIN } from "../constants";

export async function getShiptos(page, size, salesTerritory) {
  return axios({
    url: API_DOMAIN + "/api/v2/shiptos",
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("MFE_TOKEN"),
    },
    params: {
      page: page,
      size: size,
      salesTerritory: salesTerritory,
    },
  });
}

export async function getSalesRegions(businessUnitCode, userId) {

  const response = await axios({
    url: API_DOMAIN + "/api/v2/salesRegions",
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("MFE_TOKEN"),
    },
    params: {
      businessUnitCode: businessUnitCode,
      userId: userId
    },
  });

  return response.data;
}

export async function getSalesTerritories(businessUnitCode, salesRegion) {

  const response = await axios({
    url: API_DOMAIN + "/api/v2/salesTerritories",
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("MFE_TOKEN"),
    },
    params: {
      businessUnitCode: businessUnitCode,
      salesRegion: salesRegion,
    },
  });

  return response.data;
}