import {FormControl, InputLabel, MenuItem, Select, useTheme} from "@mui/material";
import {useBusinessUnitCodes} from "../hooks/useBusinessUnitCodes";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getBusinessUnitCodes} from "../services/UserService";
import {v4 as uuidV4} from 'uuid';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const MySelect = ({label, options, value = '', editable = true, required = false, autoSelect, onChange, ...props}) => {

    // console.log('Rendering MySelect: ' + label);
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = useState(value);
    const [labelId] = useState(() => uuidV4());

    useEffect(() => {
        // console.log('Side Effect of MySelect: ' + label);
        // console.log('options: ' + JSON.stringify(options));
        // console.log('selectedValue: ' + selectedValue);

        if (options && options.length > 0 && autoSelect) {
            let selectedOption;
            if (value) {
                selectedOption = options.find(option => option.id === value);
            }
            if (selectedOption) {
                setSelectedValue(selectedOption.id);
                onChange(selectedOption.id);
            } else {
                setSelectedValue(options[0].id);
                onChange(options[0].id);
            }
        }
    }, [value]);

    return (
        <FormControl
            fullWidth
            variant="outlined"
            // margin="dense"
            size="small"
            required={required}
        >
            <InputLabel
                // id={labelId}
                // shrink={true}
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    top: '1rem',
                    '&.MuiInputLabel-shrink': {
                        // styles when the label is shrunk
                        // fontSize: '1rem', // adjust as needed
                    },
                    '&.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
                        // styles when the label is not shrunk
                        // fontSize: '1.1rem', // adjust as needed
                        transform: 'translate(1rem, 0) scale(1)', // adjust as needed
                    },
                }}
            >
                {label}
            </InputLabel>
            <Select
                // labelId={labelId}
                // label={label}
                inputProps={{ readOnly: !editable }}
                onChange={(event) => {
                    setSelectedValue(event.target.value);
                    onChange(event.target.value);
                }}
                value={selectedValue}
                disabled={!editable}
                IconComponent={KeyboardArrowDownOutlinedIcon}
                sx={{
                    height: '3.5rem', // Double the height
                    padding: '1rem 0 0 0',
                    backgroundColor: 'white',
                }}
                {...props}
            >
                {options?.map((option) => {
                    return (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                        >
                            {option.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default MySelect;