export function canLeftUserEditRightUser(leftUser, rightUser) {

    if (!leftUser || !rightUser) {
        return false;
    }
    if (leftUser.id === rightUser.id) {
        return true;
    }
    if (leftUser.isSystemAdmin) {
        return true;
    }
    if (leftUser.managedRepTypeIds) {
        return leftUser.managedRepTypeIds.includes(rightUser.repType.id);
    }

    return false;
}

export function translateRepTypeToAuthorities(repType) {

    let authorities;
    switch (repType) {
        case 'ROLE_ADMIN':
        case 'ROLE_EXP_REP':
            authorities = [repType];
            break;
        default:
            authorities = ['ROLE_EXP_REP', repType];
    }

    return authorities;
}

export function translateTextToBoolean(text) {
    if (text === 'YES') {
        return true;
    }
    if (text === 'NO') {
        return false;
    }
    return null;
}
