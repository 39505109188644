import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getManagedAuthorities, getRepPermissions} from "../services/AccountService";

export function useRepPermissions(businessUnitCode, repTypeId, enabled = true, resetKey, callback = () => {}) {

    // console.log('Rendering useRepPermissions: ' + businessUnitCode + ', ' + repTypeId);

    const [repPermissions, setRepPermissions] = useState([]);

    const {data} = useQuery({
        queryKey: ['repPermissions', businessUnitCode, repTypeId, resetKey],
        queryFn: () => getRepPermissions(businessUnitCode, repTypeId),
        retry: false,
        enabled: enabled && !!businessUnitCode && !!repTypeId,
    })

    useEffect(() => {
        console.log('useRepPermissions useEffect');
        if (data) {
            setRepPermissions(data);
            callback(data);
        }
    }, [data]);

    // useEffect(() => {
    //     // if (repPermissions.length > 0) {
    //         callback(repPermissions);
    //     // }
    // }, [repPermissions]);

    return repPermissions;
}