import {Box} from '@mui/material';
import {CheckCircle, Cancel} from "@mui/icons-material";

const MyBooleanMark = ({value}) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            {value ? <CheckCircle color="success"/> : <Cancel color="error"/>}
        </Box>
    );
};

export default MyBooleanMark;