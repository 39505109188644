import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getManagedAuthorities} from "../services/AccountService";
import {getReps, getUser} from "../services/UserService";
import {useLogout} from "./useLogout";

const initialUser = {
    repType: '',
}

export function useUser(userId, enabled = true, callback = () => {}) {

    // console.log('Rendering useUser: ' + userId);

    const [user, setUser] = useState(() => initialUser);

    const logout = useLogout();

    const {data, isError, error} = useQuery({
        queryKey: ['user', userId],
        queryFn: () => getUser(userId),
        retry: false,
        enabled: enabled && !!userId
    })

    useEffect(() => {
        console.log('useUser useEffect error');
        if (isError) {
            if (error.response.status === 401) {
                logout();
            }
        }
    }, [isError, error]);

    useEffect(() => {
        console.log('useUser useEffect');
        if (data) {
            // console.log("User Data:");
            // console.log(data);
            setUser(data);
            callback(data);
        }
    }, [data]);

    return user;
}