import React, {useEffect} from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import GreyBox from './GreyBox';

const MyMultiSelectCheckboxes = ({ options, value, onChange, title }) => {

    // console.log('Rendering MyMultiSelectCheckboxes: ' + title);

    const handleCheckboxChange = (event, option) => {
        if (event.target.checked) {
            onChange([...value, option.id]);
        } else {
            onChange(value.filter(id => id !== option.id));
        }
    };

    return (
        <GreyBox title={title}>
            <Grid container spacing={0}>
                {options.map(option => (
                    <Grid item xs={4} key={option.id}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value.includes(option.id)}
                                    onChange={(event) => handleCheckboxChange(event, option)}
                                />
                            }
                            label={option.name}
                        />
                    </Grid>
                ))}
            </Grid>
        </GreyBox>
    );
};

export default MyMultiSelectCheckboxes;