export const API_DOMAIN = process.env.REACT_APP_MFE_API_DOMAIN || "";

export const DATA_TYPES = {
    STRING: 'string',
    TIMESTAMP: 'timestamp',
    BOOLEAN: 'boolean',
    STRING_ARRAY: 'string_array',
    OBJECT: 'object',
}

export const VIEW_TYPES = {
    SINGLE_SELECT: 'single_select',
    MULTI_SELECT: 'multi_select',
    TEXT: 'text',
    CHECKBOX: 'checkbox',
}

export const REP_FIELDS = [

    {
        name: 'repType',
        key: 'repTypeId',
        dataType: DATA_TYPES.OBJECT,
        viewType: VIEW_TYPES.SINGLE_SELECT,
        label: 'Rep Type',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 1
    },
    {
        name: 'businessUnitCode',
        key: 'businessUnitCode',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.SINGLE_SELECT,
        label: 'Business Unit',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 2
    },
    {
        name: 'email',
        key: 'email',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Email',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 3
    },
    {
        name: 'firstName',
        key: 'firstName',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'First Name',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: true,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 4
    },
    {
        name: 'lastName',
        key: 'lastName',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Last Name',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: true,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 5
    },
    {
        name: 'login',
        key: 'login',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Login',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 6
    },
    {
        name: 'salesRegion',
        key: 'salesRegion',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.SINGLE_SELECT,
        label: 'Sales Region',
        modes: ['create', 'update'],
        requiredForCreate: false,
        editableByRepTypeIds: [1, 2],
        selfEditable: false,
        applicableForRepTypeIds: [3, 4, 5, 6],
        sortOrder: 7
    },
    {
        name: 'salesTerritories',
        key: 'salesTerritories',
        dataType: DATA_TYPES.STRING_ARRAY,
        viewType: VIEW_TYPES.MULTI_SELECT,
        label: 'Sales Territories',
        modes: ['create', 'update'],
        requiredForCreate: false,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: false,
        applicableForRepTypeIds: [4, 5, 6],
        sortOrder: 8
    },
    {
        name: 'activated',
        key: 'activated',
        dataType: DATA_TYPES.BOOLEAN,
        viewType: VIEW_TYPES.CHECKBOX,
        defaultValue: true,
        label: 'Activated',
        modes: ['update'],
        requiredForCreate: false,
        editableByRepTypeIds: [1, 2, 3],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 9
    },
    {
        name: 'countryCode',
        key: 'countryCode',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.SINGLE_SELECT,
        label: 'Country Code',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: true,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 10
    },
    {
        name: 'adminPortalAccess',
        key: 'adminPortalAccess',
        dataType: DATA_TYPES.BOOLEAN,
        viewType: VIEW_TYPES.CHECKBOX,
        defaultValue: false,
        label: 'Admin Portal Access',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 11
    },
    {
        name: 'orderPin',
        key: 'orderPin',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Order Pin',
        modes: ['create', 'update'],
        requiredForCreate: false,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: true,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6],
        sortOrder: 12
    },
    {
        name: 'repPermission',
        key: 'repPermissionId',
        dataType: DATA_TYPES.OBJECT,
        viewType: VIEW_TYPES.SINGLE_SELECT,
        label: 'Rep Permission',
        modes: ['create', 'update'],
        requiredForCreate: true,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6],
        sortOrder: 13
    },
    {
        name: 'phoneNumber',
        key: 'phoneNumber',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Phone Number',
        modes: ['create', 'update'],
        requiredForCreate: false,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: true,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 14
    },
    {
        name: 'shiptoId',
        key: 'shiptoId',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Ship-to',
        modes: ['create', 'update'],
        requiredForCreate: false,
        editableByRepTypeIds: [1, 2, 3, 4, 5],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6],
        sortOrder: 15
    },
    {
        name: 'lastLoginDate',
        dataType: DATA_TYPES.TIMESTAMP,
        viewType: VIEW_TYPES.TEXT,
        label: 'Last Login Date',
        modes: ['update'],
        editableByRepTypeIds: [],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 16
    },
    {
        name: 'createdBy',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Created By',
        modes: ['update'],
        editableByRepTypeIds: [],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 17
    },
    {
        name: 'createdDate',
        dataType: DATA_TYPES.TIMESTAMP,
        viewType: VIEW_TYPES.TEXT,
        label: 'Created Date',
        modes: ['update'],
        editableByRepTypeIds: [],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 18
    },
    {
        name: 'lastModifiedBy',
        dataType: DATA_TYPES.STRING,
        viewType: VIEW_TYPES.TEXT,
        label: 'Last Modified By',
        modes: ['update'],
        editableByRepTypeIds: [],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 19
    },
    {
        name: 'lastModifiedDate',
        dataType: DATA_TYPES.TIMESTAMP,
        viewType: VIEW_TYPES.TEXT,
        label: 'Last Modified Date',
        modes: ['update'],
        editableByRepTypeIds: [],
        selfEditable: false,
        applicableForRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        sortOrder: 20
    },
];

export const REP_TYPES = {
    1: {
        name: 'Global Admin',
        visibleRepTypeIds: [1, 2, 3, 4, 5, 6, 7],
        defaultRoute: '/reps',
    },
    2: {
        name: 'Business Unit Manager',
        visibleRepTypeIds: [2, 3, 4, 5, 6],
        defaultRoute: '/reps',
    },
    3: {
        name: 'Regional Sales Manager',
        visibleRepTypeIds: [3, 4, 5, 6],
        defaultRoute: '/reps',
    },
    4: {
        name: 'Sales Rep',
        visibleRepTypeIds: [6],
        defaultRoute: '/reps',
    },
    5: {
        name: 'Experiential Rep',
        visibleRepTypeIds: [6],
        defaultRoute: '/reps',
    },
    6: {
        name: 'Exp Lead',
        visibleRepTypeIds: [],
        defaultRoute: '/reps',
    },
    7: {
        name: 'Retail Store Admin',
        visibleRepTypeIds: [],
        defaultRoute: '/retail-locations',
    },
}