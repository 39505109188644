import RepsTable from './screens/reps/RepsTable';
import Orders from './screens/Orders';
import SignIn from './screens/SignIn';
import repsIcon from './assets/images/reps-icon.svg';
import ordersIcon from './assets/images/orders-icon.svg';
import EventsTable from "./screens/events/EventsTable";
import RetailLocationsTable from "./screens/retailLocations/RetailLocationsTable";

const routes = [
    {
        name: "Manage Reps",
        key: "reps",
        path: "/reps",
        icon: repsIcon,
        authRequired: true,
        component: <RepsTable/>,
        visibleRepTypes: [1, 2, 3, 4, 5, 6]
    },
    {
        name: "Manage Events",
        key: "events",
        icon: ordersIcon,
        path: "/events",
        component: <EventsTable/>,
        visibleRepTypes: [1, 2, 3, 4, 5, 6]
    },
    {
        name: "Retail Locations",
        path: '/retail-locations',
        key: 'retail-locations',
        icon: ordersIcon,
        component: <RetailLocationsTable/>,
        authRequired: true,
        visibleRepTypes: [1, 7],
    },
    {
        name: "Sign In",
        key: "sign-in",
        path: "/sign-in",
        component: <SignIn/>,
    },
];

export default routes;
