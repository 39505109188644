import React from 'react';
import {Box, Typography} from '@mui/material';

const GreyBox = ({title, children}) => {
    return (
        <Box bgcolor="grey.100" py={1} px={2}>
            {title &&
                <Typography
                    variant="body2"
                    sx={{textTransform: 'uppercase', fontWeight: 'bold'}}
                >
                    {title}
                </Typography>
            }
            {children}
        </Box>
    );
};

export default GreyBox;