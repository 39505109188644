export function trimToNull(str) {
    const trimmed = str.trim();
    return trimmed === '' ? null : trimmed;
}

export function extractFirstAndLastNameFromEmail(email) {
    // If No @, set both to input.
    // Otherwise, for text before first @
    //  If No . set both to text before @
    //  Set first name to text before first . and last name to text after (and before @ )

    const atIndex = email.indexOf('@');
    if (atIndex === -1) {
        return {
            firstName: capitalizeFirstLetter(email),
            lastName: capitalizeFirstLetter(email)
        };
    }

    const beforeAt = email.substring(0, atIndex);
    const dotIndex = beforeAt.indexOf('.');
    if (dotIndex === -1) {
        return {
            firstName: capitalizeFirstLetter(beforeAt),
            lastName: capitalizeFirstLetter(beforeAt)
        };
    }

    return {
        firstName: capitalizeFirstLetter(beforeAt.substring(0, dotIndex)),
        lastName: capitalizeFirstLetter(beforeAt.substring(dotIndex + 1))
    };
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}