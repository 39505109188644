import axios from "axios";
import {API_DOMAIN} from "../constants";

export async function getCountries(businessUnitCode) {

    const response = await axios({
        url: API_DOMAIN + "/api/v2/location/countries",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        params: {
            businessUnitCode: businessUnitCode,
        },
    });

    return response.data;
}
