import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getManagedAuthorities} from "../services/AccountService";
import {useCurrentUser} from "./useCurrentUser";

export function useManagedRepTypes(userId, enabled = true, resetKey, callback = () => {
}) {

    // console.log('useManagedRepTypes: ' + userId);

    const [repTypes, setRepTypes] = useState([]);

    // console.log('repTypes: ' + JSON.stringify(repTypes));

    // use useQuery to fetch business unit data
    const {data} = useQuery({
        queryKey: ['repTypes', userId, resetKey],
        queryFn: () => getManagedAuthorities(userId),
        enabled: !!userId && enabled,
    })

    // console.log('data: ' + JSON.stringify(data));

    useEffect(() => {
        console.log('useManagedRepTypes useEffect');
        if (data) {
            // const newRepTypes = data.map((name) => ({id: name, name: name}));
            // setRepTypes(newRepTypes);
            // callback(newRepTypes);
            setRepTypes(data);
            callback(data);
        }
    }, [data]);

    // if (repTypes) {
    //     callback(repTypes);
    // }

    // useEffect(() => {
    //     // if (repTypes.length > 0) {
    //         callback(repTypes);
    //     // }
    // }, [repTypes]);

    return repTypes;
}