import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSalesRegions, getSalesTerritories} from "../services/IsfaService";

export function useSalesTerritories(businessUnitCode, salesRegion, enabled = true, callback = () => {}) {

    // console.log('Rendering useSalesTerritories');

    const [salesTerritories, setSalesTerritories] = useState([])

    const {data, isSuccess} = useQuery({
        queryKey: ['salesTerritories', businessUnitCode, salesRegion],
        queryFn: () => getSalesTerritories(businessUnitCode, salesRegion),
        enabled: enabled,
        retry: false
    })

    useEffect(() => {
        console.log("useSalesTerritories useEffect");
        if (data) {
            // setSalesTerritories(data.map((name) => ({id: name, name: name})));
            const newSalesTerritories = data.map((item) => {
                let displayName = item.name;
                if (!!item.salesRepLastName) {
                    displayName += ' (' + item.salesRepLastName + ')';
                }
                return {...item, id: item.name, name: displayName};
            });
            setSalesTerritories(newSalesTerritories);
            callback(newSalesTerritories);
        }
    }, [data]);

    // useEffect(() => {
    //     // if (salesTerritories.length > 0) {
    //         callback(salesTerritories);
    //     // }
    // }, [salesTerritories]);

    return salesTerritories;
}