import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import Raleway from './assets/fonts/Raleway-VariableFont_wght.ttf';
import GolosUI from './assets/fonts/Golos UI_VF.ttf';
import RalewayItalic from './assets/fonts/Raleway-Italic-VariableFont_wght.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#00A3FF',
        },
        divider: 'rgba(0, 0, 0, 0.2)',
        background: {
            secondary: 'rgba(0, 0, 0, 0.04)',
        },
    },
    typography: {
        fontFamily: 'PrimaryFont',
        // fontSize: 14,
        h1: {
            fontSize: '42px',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
        },
        body1: {
            fontSize: '16px',
            fontWeight: 450,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Raleway';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('Raleway'), local('Raleway-Regular'), url(${Raleway}) format('ttf');
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: { // styles applied to the Button root element
                    height: '3rem',
                    borderRadius: '1.5rem',
                    boxShadow: 'none',
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    padding: '0 2rem',
                },
            },
        },
        MuiListItemText: { // Add this section
            styleOverrides: {
                primary: { // styles applied to the primary variant
                    // fontSize: '15px',
                },
            },
        },
        MuiOutlinedInput: { // Add this section
            styleOverrides: {
                root: { // styles applied to the root variant
                    borderRadius: '0px', // Set border radius to 0px
                    // fontSize: '1rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: { // styles applied to the MenuItem root element
                    // fontSize: '14px', // Set the font size to 14px
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1rem', // Set the font size to 16px
                },
            },
        },
    },
});

const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App/>
        {/*<ReactQueryDevtools />*/}
      </QueryClientProvider>
    </ThemeProvider>
  </BrowserRouter>,
);
