import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSalesRegions} from "../services/IsfaService";
import {getCountries} from "../services/LocationService";

export function useCountries(businessUnitCode, enabled = true, callback = () => {}) {

    // console.log('Rendering useCountries');

    const [countries, setCountries] = useState([])

    const {data} = useQuery({
        queryKey: ['countries', businessUnitCode],
        queryFn: () => getCountries(businessUnitCode),
        retry: false,
        enabled: enabled && !!businessUnitCode,
    })

    useEffect(() => {
        console.log('useCountries useEffect');
        if (data) {
            const newCountries = data.map((o) => ({id: o.alpha2Code, name: o.friendlyName}));
            setCountries(newCountries);
            callback(newCountries);
        }
    }, [data]);

    return countries;
}