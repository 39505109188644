import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getManagedAuthorities} from "../services/AccountService";
import {getReps} from "../services/UserService";
import {useLogout} from "./useLogout";
import {getEvents} from "../services/EventService";

export function useEvents(page, size, enabled = true) {

    const [events, setEvents] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const logout = useLogout();

    if (!page) {
        page = 0;
    }
    if (!size) {
        size = 10;
    }

    const {data, isError, error, refetch} = useQuery({
        queryKey: ['events', page, size],
        queryFn: () => getEvents(
            page,
            size,
            'DESC'
        ),
        enabled: enabled,
        retry: false
    })

    useEffect(() => {
        if (isError && error.response && error.response.status) {
            if (error.response.status === 401) {
                logout();
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (data) {
            setEvents(data.events);
            setTotalCount(data.totalCount);
        }
    }, [data]);

    return [events, totalCount, refetch];
}