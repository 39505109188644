import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getManagedAuthorities} from "../services/AccountService";
import {getReps} from "../services/UserService";
import {useLogout} from "./useLogout";

export function useReps(businessUnitCode, repTypeId, activated, salesRegion, salesTerritory, page, size, enabled = true) {

    const [reps, setReps] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const logout = useLogout();

    const {data, isError, error, refetch} = useQuery({
        queryKey: ['reps', businessUnitCode, repTypeId, activated, salesRegion, salesTerritory, page, size],
        queryFn: () => getReps(
            businessUnitCode,
            repTypeId,
            activated,
            salesRegion,
            salesTerritory,
            page,
            size
        ),
        enabled: enabled && !!businessUnitCode,
        retry: false
    })

    useEffect(() => {
        if (isError && error.response && error.response.status) {
            if (error.response.status === 401) {
                logout();
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (data) {
            setReps(data.reps);
            setTotalCount(data.totalCount);
        }
    }, [data]);

    return [reps, totalCount, refetch];
}