import {useEffect, useRef, useState} from 'react';

// react-router-dom components
import {useNavigate} from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';

// Images
import signInBgImg from '../assets/images/mfe-hero-img@2x.jpg';
import {Alert, AlertTitle, Box, Button, Paper, TextField, Typography} from '@mui/material';
import {authenticate, canCurrentUserAccessAdminPortal} from '../services/AccountService';
import {canUserAccessAdminPortal, clearCurrentUser} from "../util/AuthUtil";
import {useMutation} from "@tanstack/react-query";
import {useCurrentUser} from "../hooks/useCurrentUser";

function SignIn() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [signInError, setSignInError] = useState({error: false, title: "", message: ""});

    const [currentUser, updateCurrentUser] = useCurrentUser();

    const {mutate: signIn} = useMutation({
        mutationFn: authenticate,
        onSuccess: (data) => {
            console.log('Sign in successful');
            console.log(data);
            localStorage.setItem('MFE_TOKEN', data.authToken);
            // localStorage.setItem('MFE_CURRENT_USER', JSON.stringify(data.userData));
            let currentUser = data.userData;
            updateCurrentUser(currentUser);
        },
        onError: (error) => {
            console.log('Sign in failed');
            console.log(error);
            let errorMessage = error.response.data && error.response.data.message;
            setSignInError({error: true, title: "Sign In Failed", message: errorMessage || "Unknown Error Occurred"});
            clearCurrentUser();
        }
    });

    useEffect(() => {
        if (currentUser) {
            let message = canUserAccessAdminPortal(currentUser);
            if (message === null) {
                navigate('/reps');
            } else {
                setSignInError({error: true, title: "Unauthorized", message: message});
                clearCurrentUser();
            }
        } else {
            clearCurrentUser();
        }
    }, [currentUser]);

    // const handleSignIn = async () => {

        // const response = await authenticate(emailInput.current.value,
        //     passwordInput.current.value);
        //
        // if (response.status === 200) {
        //     console.log('Sign in successful');
        //     console.log(response);
        //     let token = response.headers.get('Authorization');
        //     localStorage.setItem('MFE_TOKEN', token);
        //     localStorage.setItem('MFE_CURRENT_USER', JSON.stringify(response.data));
        // } else {
        //     console.log('Sign in failed');
        //     let errorMessage = response.data && response.data.message;
        //     setSignInError({error: true, title: "Sign In Failed", message: errorMessage || "Unknown Error Occurred"});
        //     clearCurrentUser();
        //     return;
        // }

        // await signIn({login: emailInput.current.value, password: passwordInput.current.value});
        // let isAdmin = await canCurrentUserAccessAdminPortal();
        // let isAdmin = canUserAccessAdminPortal(currentUser);
        // if (!isAdmin) {
        //     setSignInError({error: true, title: "Unauthorized", message: "Please Log In as an Admin Portal User."});
        //     clearCurrentUser();
        //     return;
        // }
        //
        // clearSignInError();
        //
        // navigate('/reps');
    // };

    // const clearSignInError = () => {
    //     setSignInError({error: false, title: "", message: ""});
    // }

    // const handleEmailChange = (event) => {
    //   setEmail(event.target.value);
    // };
    // const handlePasswordChange = (event) => {
    //   setPassword(event.target.value);
    // };

    return (
        <>
            <Box sx={{
                position: 'absolute',
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${signInBgImg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                filter: 'blur(5px)',
                zIndex: -1,
            }}/>
            <Grid container spacing={1} justifyContent="center" alignItems="center"
                  height="100%">
                <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                    <Paper elevation={5} sx={{p: 2}}>
                        <Paper
                            elevation={5}
                            sx={{
                                backgroundColor: 'primary.main',
                                p: 2,
                                mt: -6,
                            }}
                        >
                            <Typography variant="h4" fontWeight="medium" color="white"
                                        align="center">
                                MFE Admin Portal
                            </Typography>
                        </Paper>
                        <Box pt={4} pb={3} px={3}>
                            <Box component="form" role="form">
                                <Box mb={2}>
                                    <TextField
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        type="text"
                                        label="Login"
                                        InputLabelProps={{shrink: !!email}}
                                        fullWidth
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        type="password"
                                        label="Password"
                                        InputLabelProps={{shrink: !!password}}
                                        fullWidth
                                    />
                                </Box>
                                <Box mt={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => signIn({login: email, password: password})}
                                        sx={{
                                            p: 1.5,
                                        }}
                                    >
                                        <Typography>sign in</Typography>
                                    </Button>
                                </Box>
                                {signInError.error && (
                                    <Box mt={2}>
                                        <Alert severity="error">
                                            <AlertTitle>{signInError.title}</AlertTitle>
                                            {signInError.message}
                                        </Alert>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default SignIn;
