import axios from "axios";
import {API_DOMAIN} from "../constants";

export async function getReps(businessUnitCode, repTypeId, activated, salesRegion, salesTerritory, page, size) {

    const response = await axios({
        url: API_DOMAIN + "/api/v2/users",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        params: {
            businessUnitCode: businessUnitCode,
            repTypeId: repTypeId,
            salesRegion: salesRegion,
            salesTerritory: salesTerritory,
            authority: "ROLE_EXP_REP",
            activated: activated,
            page: page,
            size: size,
        },
    });

    return {reps: response.data, totalCount: parseInt(response.headers['x-total-count'])};
}

export async function getBusinessUnitCodes() {
    const response = await axios({
        url: API_DOMAIN + "/api/v2/user/current/businessUnitCodes",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
    });

    return response.data;
}

export async function createUser(payload) {
    return axios({
        url: API_DOMAIN + "/api/v2/users",
        method: "POST",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        data: payload,
    });
}

export async function updateUser(payload) {
    return axios({
        url: API_DOMAIN + "/api/v2/users",
        method: "PUT",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        data: payload,
    });
}

export async function getUser(userId) {

    const response = await axios({
        url: API_DOMAIN + `/api/v2/users/${userId}`,
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
    });

    return response.data;
}
