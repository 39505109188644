import {Box, Button} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useLocalStorage} from "../../hooks/useLocalStorage";
import PageHeader from "../../components/PageHeader";
import MyBooleanMark from "../../components/MyBooleanMark";
import {useEvents} from "../../hooks/useEvents";
import {useEventTypes} from "../../hooks/useEventTypes";
import {useBookabilityLevels} from "../../hooks/useBookabilityLevels";
import {useSpecsVisibilities} from "../../hooks/useSpecsVisibilities";
import {toPercentage} from "../../util/NumberUtil";
import {useNavigate} from "react-router-dom";
import {getCurrentUser} from "../../util/AuthUtil";
import routes from "../../routes";
import {REP_TYPES} from "../../constants";

const RetailLocationsTable = () => {

    const navigate = useNavigate();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (!routes.find(route => route.key === 'retail-locations').visibleRepTypes.includes(currentUser.repType.id)) {
            navigate(REP_TYPES[currentUser.repType.id].defaultRoute);
        }
    }, [currentUser, navigate]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PageHeader title="Manage Retail Locations"/>
            </Box>
        </>
    );
};

export default RetailLocationsTable;
