import {useNavigate} from 'react-router-dom';

export function useLogout() {
  const navigate = useNavigate();

  return () => {
    // Clear user-related data from localStorage
    localStorage.removeItem("MFE_TOKEN");
    localStorage.removeItem("MFE_CURRENT_USER");

    // Navigate to the /sign-in page
    navigate('/sign-in');
  };
}
