import axios from "axios";
import {API_DOMAIN} from "../constants";

export async function getEvents(page, size, sortOrder) {

    const response = await axios({
        url: API_DOMAIN + "/api/v2/adminPortal/events",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        params: {
            page: page,
            size: size,
            sortOrder: sortOrder,
        },
    });

    return {events: response.data, totalCount: parseInt(response.headers['x-total-count'])};
}
