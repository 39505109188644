import {Box, Container, CssBaseline} from '@mui/material';
import Sidebar from './screens/Sidebar';
import {BrowserRouter, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import routes from './routes';
import AuthProtector from './components/AuthProtector';

export default function App() {

  const location = useLocation();

  return (
      <Box sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
      }}>
        <CssBaseline/>
        {location.pathname !== '/sign-in' && <Sidebar/>}
        <Routes>
          {
            routes.map(({path, key, component, authRequired}) => {
              if (authRequired) {
                return (
                    <Route key={key} path={path}
                           element={<AuthProtector>{component}</AuthProtector>}/>
                );
              } else {
                return (
                    <Route key={key} path={path} element={component}/>
                );
              }
            })
          }
          <Route path="*" element={<Navigate to="/reps"/>}/>
        </Routes>
      </Box>
  );
}
