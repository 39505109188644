import React from 'react';
import Topbar from './Topbar';
import {Box, Typography, useTheme} from "@mui/material";

const PageHeader = ({title, children}) => {

    const theme = useTheme();

    return (
        <Box
            sx={{
                px: 3,
                py: 2,
                backgroundColor: theme.palette.background.secondary,
            }}
        >
            <Typography variant="h1" component="div">
                {title}
            </Typography>
            {children}
        </Box>
    );
};

export default PageHeader;