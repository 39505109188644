import {API_DOMAIN} from "../constants";
import axios from "axios";
import {getCurrentUser} from "../util/AuthUtil";

export async function authenticate(payload) {
    let data = new URLSearchParams();
    data.append("login", payload.login);
    data.append("password", payload.password);
    data.append("details", "true");

    const response = await axios({
        url: API_DOMAIN + "/api/v2/authentication",
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
        },
        data: data,
    });

    const authToken = response.headers.get('Authorization');

    return {
        userData: response.data,
        authToken
    }
}

export async function getAuthorities() {
    return axios({
        url: API_DOMAIN + "/api/v2/authorities",
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
    });
}

export async function canCurrentUserAccessAdminPortal() {
    // let authorities = await getAuthorities();
    //
    // return authorities.data.some((authority) => authority === "ROLE_ADMIN");
    let currentUser = getCurrentUser();
    return currentUser && currentUser.adminPortalAccess;
}

export async function getManagedAuthorities(userId) {
    const response = await axios({
        url: API_DOMAIN + "/api/v2/repTypes",
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        params: {
            managedByUserId: userId,
        },
    });

    return response.data;
}

export async function getRepPermissions(businessUnitCode, repTypeId) {
    const response = await axios({
        url: API_DOMAIN + "/api/v2/repPermissions",
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: localStorage.getItem("MFE_TOKEN"),
        },
        params: {
            businessUnitCode: businessUnitCode,
            repTypeId: repTypeId,
        },
    });

    return response.data;
}
