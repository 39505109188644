import { Box, Typography } from '@mui/material';

const MySidebarItem = ({ src, alt, text, onClick, backgroundColor = 'white' }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: 1.5,
                cursor: onClick ? 'pointer' : 'default',
                backgroundColor: backgroundColor,
            }}
            onClick={onClick}
        >
            <Box
                component="img"
                src={src}
                alt={alt}
                sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                }}
            />
            <Typography align="center" mt="auto">
                {text}
            </Typography>
        </Box>
    );
};

export default MySidebarItem;