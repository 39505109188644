import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {getReps} from "../services/UserService";
import {getAuthorities, canCurrentUserAccessAdminPortal} from "../services/AccountService";
import {clearCurrentUser} from "../util/AuthUtil";

export default function AuthProtector({children}) {

  console.log('Rendering AuthProtector');

  const mfeToken = localStorage.getItem("MFE_TOKEN");
  if (!mfeToken) {
    clearCurrentUser();
    return <Navigate to="/sign-in"/>;
  }

  canCurrentUserAccessAdminPortal().then((res) => {
    console.log(res);
    if (!res) {
      clearCurrentUser();
      return <Navigate to="/sign-in"/>;
    }
  }).catch((err) => {
    console.log(err);
    clearCurrentUser();
    return <Navigate to="/sign-in"/>;
  });

  return children;
}
